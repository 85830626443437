<template lang="pug">
  div.wrap-module-trainers-list
    div.f.fm.flex-between.mb12.mt20
      h2 トレーナー
    div(v-if="activeEmails && superAdminEmails && myEmail").wrap-cards.mt14
      ItemTrainerCard(
        v-for="item in trainers"
        :content="item"
        :activeEmails="activeEmails"
        :superAdminEmails="superAdminEmails"
        :myEmail="myEmail")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-trainers-list {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  h2 {
    color: $primary_text_color;
    font-size: 16px;
    border-bottom: $border_white;
  }
  .wrap-cards {
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}
</style>

<script>
import ItemTrainerCard from '@/components/item/ItemTrainerCard.vue'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemTrainerCard
  },
  props: {
  },
  data () {
    return {
      trainers: [],
      activeEmails: [],
      superAdminEmails: [],
      myEmail: null
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.trainers = await this.getAllUsers()
    this.activeEmails = await this.getActiveEmails()
    this.superAdminEmails = await this.getSuperAdminEmails()
    this.myEmail = this.trainers.filter(e => {
      return e.uid === this.uid
    })[0].email
  },
  methods: {
    ...mapActionsAuth([
      'getAllUsers',
      'getActiveEmails',
      'getSuperAdminEmails'
    ])
  }
}
</script>
