<template lang="pug">
  div.wrap-item-trainer-card.f.fm.flex-between.px14.py14
    div.wrap-trainer-info.f.fm.flex-between
      div.f.fm
        div.wrap-img.f.fh.mr12
          img(:src="content.photoURL")
        div.wrap-profile
          div.f.fm
            span.name.mr6 {{content.name}}
            //- span(v-if="isAdmin").mr6.pt2 Admin
            v-icon(
              v-if="!isActivated && IamSuperAdmin"
              @click="activate"
              size="16px").pb2 lock
          span.email.line-clamp-1 {{content.email}}
      //- div(v-if="!isActivated && IamSuperAdmin").wrap-active
        span(@click="activate").button-activate アクティベート
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-item-trainer-card {
  background: $primary_bg_color;
  border-bottom: $secondary_border;
  .wrap-trainer-info {
    width: 100%;
    .wrap-img {
      width: 62px;
      height: 62px;
      overflow: hidden;
      border: $secondary_border;
      border-radius: 50%;
      img {
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .wrap-profile {
      span {
        display: block;
      }
      .name {
        font-size: 18px;
      }
      .email {
        font-size: 14px;
        color: #999;
        max-width: 244px;
      }
    }
    .wrap-active {
      .button-activate {
        font-size: 12px;
        color: #2196f3;
        cursor: pointer;
      }
    }
  }
}
</style>

<script>
import moment from 'moment'

import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    activeEmails: {
      type: Array,
      required: true
    },
    superAdminEmails: {
      type: Array,
      required: true
    },
    myEmail: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isAdmin: false,
      isActivated: false,
      IamSuperAdmin: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    this.isAdmin = this.superAdminEmails.includes(this.content.email)
    this.IamSuperAdmin = this.superAdminEmails.includes(this.myEmail)
    this.isActivated = this.activeEmails.includes(this.content.email)

    // setTimeout(() => {
    //   var isActivated = this.activeEmails.filter(e => { return e === this.content.email })[0]
    //   // if (isActivated) this.isActivated = true
    // }, 200)
  },
  methods: {
    ...mapActionsAuth([
      'activateTrainer'
    ]),
    async activate () {
      if (!this.IamSuperAdmin) {
        alert('アドミンユーザーのみアクティベートできます。')
        return false
      }

      var letActivate = confirm(`${this.content.name}のアカウントをアクティベートしますか？`)
      if (letActivate) {
        await this.activateTrainer(this.content.email)
        this.isActivated = true
      }

      // mixpanel.track('onActivate', { createdAt: new Date() })
    }
  }
}
</script>
