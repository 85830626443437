<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    div.wrap-trainers
      ModuleTrainersList(v-if="uid")

</template>

<style lang="scss" scoped>
.wrap-trainers {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header.vue'
import ModuleTrainersList from '@/components/module/ModuleTrainersList.vue'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    ModuleTrainersList
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {

  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    }
  }
}
</script>
